import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Select, Input, message, Button } from 'antd';
import DataTable from '../../../components/Table/Table';

const { Option } = Select;

const AddWalletAddress = () => {
  const [currency, setCurrency] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [transactionPassword, setTransactionPassword] = useState('');
  const token = localStorage.getItem('userToken');
  const [teamData, setTeamData] = useState([]); 
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/wallet-addresses',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.request(config);
       
          setTeamData(response.data); 
       
      } catch (error) {
        console.error('Error fetching mining data:', error);
        message.error('Error fetching mining data');
      }
    };

    fetchTeamData();
  }, [token]);
  const handleUpdateClick = async () => {
    try {
      const data = JSON.stringify({
        currency,
        walletAddress,
        transactionPassword
      });

      const config = {
        method: 'post',
        url: 'https://api.vertexglobal.io/auth/v1/wallet-address',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: data
      };

      const response = await axios.request(config);
      message.success('Wallet address updated successfully!');
      setCurrency('');
      setWalletAddress('');
      setTransactionPassword('');
    } catch (error) {
      console.error('Error updating wallet address:', error);
      message.error('Failed to update wallet address.');
    }
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Add Wallet Address</h1>
        <div className="form-comp full">
          <div className="form-floating mb-3">
            <Select
              placeholder="Select Currency"
              className="form-control"
              id="floatingSelectCurrency"
              value={currency}
              onChange={value => setCurrency(value)}
              style={{ width: '100%' }}
            >
              <Option value="BTC">Bitcoin (BTC)</Option>
              <Option value="ETH">Ethereum (ETH)</Option>
              <Option value="USDT">Tether (USDT)</Option>
            </Select>
            <label htmlFor="floatingSelectCurrency">Select Currency *</label>
          </div>

          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingWalletAddress"
              placeholder="Wallet Address"
              value={walletAddress}
              onChange={e => setWalletAddress(e.target.value)}
            />
            <label htmlFor="floatingWalletAddress">Wallet Address *</label>
          </div>

         

          <Button type="primary" id="primary-btn" onClick={handleUpdateClick}>
            Update
          </Button>
        </div>
      </div>
      <DataTable heading="My Wallet Address" data={teamData || []} />
    </main>
  );
};

export default AddWalletAddress;
