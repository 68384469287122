import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, message } from 'antd'; // Import antd components

const ClaimReward = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fetchMiningData();
  }, []);

  const fetchMiningData = async () => {
    try {
      const response = await axios.get('https://api.vertexglobal.io/auth/v1/check-reward', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}` 
        }
      });

      setData(response.data);
    } catch (error) {
      console.error('Error fetching reward data:', error);
      message.error('Failed to load reward data');
    }
  };

  const handleClaim = async () => {
    try {
      let response = await axios.get('https://api.vertexglobal.io/auth/v1/check-reward', {
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('userToken')}` 
        }
      });

      console.log(response.data);
      message.success('Reward claimed successfully!');
    } catch (error) {
      console.error('Error claiming reward:', error);
      message.error('Failed to claim reward');
    }
  };

  return (
    <>
      {data.status && (
        <>
          <h5>{data.message}</h5>
          <Button 
            type="primary" 
            onClick={handleClaim} 
           id="primary-btn"
          >
            Claim Now
          </Button>
        </>
      )}
    </>
  );
};

export default ClaimReward;
