import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Input, DatePicker, Select } from 'antd';
import DataTable from '../../components/Table/Table';
import './Support.css';

const { Option } = Select;

const Support = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTickets();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const { type, priority, message } = values;

      const data = JSON.stringify({
        type,
        priority,
        message
      });

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.vertexglobal.io/auth/v1/ticket',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        },
        data: data
      };

      await axios.request(config);
      form.resetFields();
      setIsModalVisible(false);
      fetchTickets(); // Refresh ticket list after submission
    } catch (error) {
      console.error('Error creating ticket:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchTickets = async () => {
    try {
      const response = await axios.get('https://api.vertexglobal.io/auth/v1/tickets', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      setTickets(response.data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  return (
    <>
      <div className='support-btn'>
        <Button type="primary" id="primary-btn" onClick={showModal}>
          Create new ticket
        </Button>
      </div>

      <DataTable heading="All Tickets" data={tickets} />

      <Modal
        title="Create New Ticket"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: 'Please select the type' }]}
          >
            <Select placeholder="Select ticket type">
              <Option value="Technical">Technical</Option>
              <Option value="Billing">Billing</Option>
              <Option value="General">General</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Priority"
            name="priority"
            rules={[{ required: true, message: 'Please select the priority' }]}
          >
            <Select placeholder="Select priority">
              <Option value="Low">Low</Option>
              <Option value="Medium">Medium</Option>
              <Option value="High">High</Option>
              <Option value="Urgent">Urgent</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Message"
            name="message"
            rules={[{ required: true, message: 'Please enter the message' }]}
          >
            <Input.TextArea rows={4} placeholder="Enter the message" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Support;
