import React, { useEffect, useState } from 'react';
import { Collapse, message } from 'antd';
import axios from 'axios';
import { Table, Pagination } from 'react-bootstrap';
import moment from 'moment';
import './Level.css';

const { Panel } = Collapse;

const LevelTeam = () => {
  const [levelTeamData, setLevelTeamData] = useState([]);

  useEffect(() => {
    const fetchLevelTeam = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/level-team',
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`
          }
        };

        const response = await axios.request(config);
        const { data } = response.data;

        // Set the data to state
        setLevelTeamData(data);
      } catch (error) {
        message.error('Failed to fetch level team data.');
        console.error(error);
      }
    };

    fetchLevelTeam();
  }, []);

  return (
    <div className="main-content custom-page">
      <h4>Level Team Structure</h4>
      {levelTeamData.length > 0 ? (
        <Collapse accordion>
          {levelTeamData.map((level) => (
            <Panel header={`Level ${level.level} (Total Members: ${level.users.length})`} key={`level-${level.level}`}>
              {level.users.map((user, index) => (
                <Collapse key={user.userName} accordion>
                  <Panel header={`${user.fullName} (${user.userName})`} key={`${user.userName}-${index}`}>
                    <div className="table-responsive"> 
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          
                            <th>Full Name</th>
                            <th>Username</th>
                            <th>Email Address</th>
                            <th>Total Fund</th>
                            <th>Status</th>
                            <th>Activation Date</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                       
                          <tr>
                           
                              <td >{user.fullName}</td>
                              <td >{user.userName}</td>
                              <td >{user.email}</td>
                              <td >{user.funds}</td>
                              <td > {user.funds>0 ? <>Active</>:<>In Active</>}</td>
                              <td > {moment(user.firstCreditDate).format('DD-MM-YYYY | HH:MM')}</td>    
                          </tr>
                       
                      </tbody>
                    </Table>
                    </div>
                  </Panel>
                </Collapse>
              ))}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <p>Loading team data...</p>
      )}
    </div>
  );
};

export default LevelTeam;
