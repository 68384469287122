import React, { useState } from 'react';
import { Select, message, Input, Button } from 'antd';

const { Option } = Select;

const UpdateWalletAddress = () => {
  const [currency, setCurrency] = useState(null);
  const [currentAddress, setCurrentAddress] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);

  // Retrieve the token from localStorage or wherever it's stored
  const token = localStorage.getItem('userToken');

  const handleCurrencyChange = value => {
    setCurrency(value);
  };

  const handleUpdateClick = async () => {
    if (!currency || !currentAddress || !newAddress) {
      message.error('Please fill in all required fields!');
      return;
    }

    try {
      // Send OTP request
      const response = await fetch('https://api.vertexglobal.io/auth/v1/packages/', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token here
        },
        body: JSON.stringify({
          packageaddress: currentAddress,
        }),
      });

      const data = await response.json();

      if (data.status) {
        message.success(data.message);
        setIsOtpSent(true);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error('Failed to send OTP. Please try again.');
      console.error('Error:', error);
    }
  };

  const handleOtpSubmit = async () => {
    if (!otp) {
      message.error('Please enter the OTP!');
      return;
    }

    try {
      // Verify OTP and update wallet address
      const response = await fetch('https://api.vertexglobal.io/auth/v1/packages/', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token here
        },
        body: JSON.stringify({
          packageaddress: currentAddress,
          newPackageId: newAddress,
          otp: otp,
        }),
      });

      const data = await response.json();

      if (data.status) {
        message.success('Wallet address updated successfully!');
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 1000); // Adjust the delay if needed
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error('Failed to update wallet address. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Update Wallet Address</h1>
        <div className="form-comp full">
          <div className="form-floating mb-3">
            <Select
              placeholder="Select Currency"
              className="form-control"
              id="floatingSelectCurrency"
              style={{ width: '100%' }}
              onChange={handleCurrencyChange}
            >
              <Option value="btc">Bitcoin (BTC)</Option>
              <Option value="eth">Ethereum (ETH)</Option>
              <Option value="usdt">Tether (USDT)</Option>
            </Select>
            <label htmlFor="floatingSelectCurrency">Select Currency *</label>
          </div>

          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingCurrentWalletAddress"
              placeholder="Current Wallet Address"
              value={currentAddress}
              onChange={(e) => setCurrentAddress(e.target.value)}
            />
            <label htmlFor="floatingCurrentWalletAddress">Current Wallet Address *</label>
          </div>

          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingNewWalletAddress"
              placeholder="New Wallet Address"
              value={newAddress}
              onChange={(e) => setNewAddress(e.target.value)}
            />
            <label htmlFor="floatingNewWalletAddress">New Wallet Address *</label>
          </div>

          {isOtpSent && (
            <div className="form-floating mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingOtp"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <label htmlFor="floatingOtp">Enter OTP *</label>
            </div>
          )}

          {!isOtpSent ? (
            <Button id="primary-btn" onClick={handleUpdateClick}>
              Send OTP
            </Button>
          ) : (
            <Button id="primary-btn" onClick={handleOtpSubmit}>
              Verify OTP
            </Button>
          )}
        </div>
      </div>
    </main>
  );
};

export default UpdateWalletAddress;
