import React, { useState } from 'react';
import { message } from 'antd';
import axios from 'axios';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const token = localStorage.getItem('userToken');

  const handleUpdateClick = async () => {
    if (newPassword !== confirmNewPassword) {
      message.error('New passwords do not match');
      return;
    }

    try {
      

      const data = JSON.stringify({
        currentPassword,
        newPassword
      });

      const response = await axios.patch('https://api.vertexglobal.io/auth/v1/user/', data, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      console.log(response.data);
      message.success('Password updated successfully!');
    } catch (error) {
      console.log(error);
      message.error('Failed to update password');
    }
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Change Password</h1>
        <div className="form-comp full">
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="currentPassword"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <label htmlFor="currentPassword">Current Password</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="newPassword"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <label htmlFor="newPassword">New Password</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="confirmNewPassword"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <label htmlFor="confirmNewPassword">Confirm New Password</label>
          </div>
          <button id="primary-btn" onClick={handleUpdateClick}>Save</button>
        </div>
      </div>
    </main>
  );
};

export default ChangePassword;
