import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import DataTable from '../../../components/Table/Table';
import axios from 'axios'; // Import axios

const { Option } = Select;

const MyTeam = () => {
  const [teamData, setTeamData] = useState([]); 
  const token = localStorage.getItem('userToken'); 

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/direct-referral',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.request(config);
        if (response.data.status) {
          setTeamData(response.data.data); 
        } else {
          message.error('Failed to fetch team data');
        }
      } catch (error) {
        console.error('Error fetching team data:', error);
        message.error('Error fetching team data');
      }
    };

    fetchTeamData();
  }, [token]);

  return (
    <main className="main-content">
      <DataTable heading="Direct Team" data={teamData} />
    </main>
  );
};

export default MyTeam;
