import React,{useState,useEffect} from 'react';
import { message } from 'antd';
import axios from 'axios';
import './Referral.css';

const ReferralInput = () => {

  const [baseUrl, setBaseUrl] = useState('');
  const token = localStorage.getItem('userToken'); 


  useEffect(() => {
    const fetchData = async () => {
      try {
 
        const response = await axios.get('https://api.vertexglobal.io/auth/v1/dashboard', {
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        });

        const data = response.data.data;
        setBaseUrl(data.referralUrl || '');

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const copyToClipboard = () => {
    navigator.clipboard.writeText(baseUrl)
      .then(() => {
        message.success('Referral url copied successfully!');
      })
      .catch(() => {
        message.error('Failed to copy URL.');
      });
  };

  return (
    <div className="referral">
      <h2>Your referral link</h2>
      <div className="referral-div">
        <input
          type="text"
          readOnly
          value={baseUrl}
        />
        <button onClick={copyToClipboard} id="primary-btn">
          Copy URL
        </button>
      </div>
    </div>
  );
};

export default ReferralInput;
