import React from 'react';
import { Select, message } from 'antd';
import DataTable from '../../../components/Table/Table';
const { Option } = Select;

const Data = {
    miningIncome: [
      {
        srNo: '1',
        userId: "VX0001",
        name: "Aman Sharma",
        amount: "200 USD",
        orderId: "VX172521376421AF5602",
        walletAddress: "0x2D88bbB191BC2EE34679433bEd00E812CA7828c8	",
        paidAmount: "102 usdtbsc",
        status: "WAITING	",
        action:'Cancle'
      },
      {
        srNo: '2',
        userId: "VX0001",
        name: "Aman Sharma",
        amount: "200 USD",
        orderId: "VX172521376421AF5602",
        walletAddress: "0x2D88bbB191BC2EE34679433bEd00E812CA7828c8	",
        paidAmount: "102 usdtbsc",
        status: "WAITING	",
        action:'Cancle'
      },
      
      {
        srNo: '1',
        userId: "VX0001",
        name: "Aman Sharma",
        amount: "200 USD",
        orderId: "VX172521376421AF5602",
        walletAddress: "0x2D88bbB191BC2EE34679433bEd00E812CA7828c8	",
        paidAmount: "102 usdtbsc",
        status: "CANCELED",
        action:'More'
      },
        
    ]
  };

const FundTransfer = () => {
  const handleUpdateClick = () => {
    message.success('Password updated successfully!');
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Fund Transfer</h1>
        <div className="form-comp full">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingInput" placeholder="Full Name" />
            <label htmlFor="floatingInput">User Id *</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingInput" placeholder="Mobile" />
            <label htmlFor="floatingInput">Name *</label>
          </div>
          

          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="floatingInput" placeholder="Email Address" />
            <label htmlFor="floatingInput">Fund Wallet *</label>
          </div>
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="floatingInput" placeholder="Email Address" />
            <label htmlFor="floatingInput">Amount To Transfer (min $ 10) *</label>
          </div>
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="floatingInput" placeholder="Email Address" />
            <label htmlFor="floatingInput">Transaction Password *</label>
          </div>
          <button id="primary-btn" onClick={handleUpdateClick}>Transfer</button>
        </div>

      
      </div>
      <DataTable heading="Fund Transfer History" data={Data.miningIncome} />
    </main>
  );
};

export default FundTransfer;
