import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import backgroundVideo from '../../../assets/Videos/back.mp4';
import { message, Select } from 'antd'; // Import message and Select from antd
import { countries } from '../../../utils/countries';
import './Signup.css';

const Signup = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        sponsorId: '',
        sponsorName: '',
        country: null,
        password: '',
        termsCondition: false,
    });

    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleCountryChange = (value) => {
        setFormData({
            ...formData,
            country: value,
        });
    };

    const handleSignup = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
    
        if (!formData.termsCondition) {
            setError("You must agree to the terms and conditions.");
            setLoading(false);
            message.error("You must agree to the terms and conditions.");
            return;
        }
    
        try {
            const response = await axios.post('https://api.vertexglobal.io/auth/v1/signup', {
                fullName: formData.fullName,
                sponsorId: formData.sponsorId,
                sponsorName: formData.sponsorName,
                country: formData.country,
                email: formData.email,
                password: formData.password,
                phone: formData.phone,
                termsCondition: formData.termsCondition
            });
    
            if (response.data.status) {
                const { userToken } = response.data;
                localStorage.setItem('userToken', userToken);
                message.success('Signup successful! Redirecting to the dashboard...');
                navigate('/dashboard');
            } else {
                setError(response.data.message);
                message.error(response.data.message);
            }
        } catch (err) {
            console.error(err);
            setError("Something went wrong. Please try again.");
            message.error("Something went wrong. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className='signup-background'>
            <video className='background-video' autoPlay muted loop>
                <source src={backgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className='overlay'></div> 
            <div className="wrapper">
                <div className="container h-100 d-flex align-items-center justify-content-center">
                    <div className="card log-cont bg-dark p-4">
                        <div className="logo-account d-flex justify-content-between align-items-center">
                            <div className='logo'>
                                <img src={Logo} alt="vertex" />
                            </div>
                            <div className='new-account'>
                                <p>Already have an account?</p>
                                <a href="/login"><button id="primary-btn">Login</button></a>
                            </div>
                        </div>
                        <div className="auth-form mt-4">
                            <h2 className="text-center mb-4">Create an account</h2>
                            <p>Vertex Decentralized Crypto World</p>
                            <form onSubmit={handleSignup} className='form-comp'>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fullName"
                                        name="fullName"
                                        placeholder="Full Name"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="fullName">Full Name</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="name@example.com"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="email">Email Address</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phone"
                                        name="phone"
                                        placeholder="Phone Number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="phone">Phone Number</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="sponsorId"
                                        name="sponsorId"
                                        placeholder="Sponsor ID"
                                        value={formData.sponsorId}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="sponsorId">Sponsor ID</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="sponsorName"
                                        name="sponsorName"
                                        placeholder="Sponsor Name"
                                        value={formData.sponsorName}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="sponsorName">Sponsor Name</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <Select
                                        options={countries}
                                        value={formData.country}
                                        onChange={handleCountryChange}
                                        placeholder="Select Country"
                                        className="form-control"
                                        id="floatingSelectCurrency"
                                        style={{ width: '100%' }}
                                        required
                                    />
                                    <label htmlFor="floatingSelectCurrency">Select Country *</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="password">Password</label>
                                </div>
                                <div className="form-check mb-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="termsCondition"
                                        name="termsCondition"
                                        checked={formData.termsCondition}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label className="form-check-label" htmlFor="termsCondition">
                                        I agree to the terms and conditions
                                    </label>
                                </div>
                                {error && <div className="text-danger text-center">{error}</div>}
                                <div className="text-center mt-3">
                                    <button type="submit" className="btn btn-primary" id="primary-btn" disabled={loading}>
                                        {loading ? "Signing up..." : "Signup"}
                                    </button>
                                </div>
                                <div className="text-center mt-3 copyrith">
                                    <p>Copyright © 2024 Vertex Global. All Rights Reserved.</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
