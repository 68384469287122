import React, { useState, useRef, useEffect } from 'react';
import { message } from 'antd';
import axios from 'axios';
import 'antd/dist/reset.css'; 
import './Wallet.css';
import Logo from '../../assets/images/logo192.png';
import Modal from 'react-modal';
import QRCode from "react-qr-code";
import { ShimmerText } from "react-shimmer-effects";
import { Table, Pagination } from 'react-bootstrap';
import moment from 'moment'
const Wallet = () => {
    const [amount, setAmount] = useState('');
    const [transactions, setTransactions] = useState([]);
    const transactionsEndRef = useRef(null);
    const [wallet, setWallet] = useState('');
    const [cryptoCurrency, setCryptoCurrency] = useState('');
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const token = localStorage.getItem('userToken');

    const copyToClipboard = () => {
        if (paymentDetails) {
            navigator.clipboard.writeText(paymentDetails.pay_address)
                .then(() => {
                    message.success(`Copied ${paymentDetails.pay_address}`);
                })
                .catch((err) => {
                    console.error('Failed to copy: ', err);
                });
        }
    };

    // const handleAddFunds = async () => {
    //     if (!amount || !cryptoCurrency) {
    //         message.error('Please enter an amount and select a cryptocurrency.');
    //         return;
    //     }

    //     setLoading(true); 

    //     try {
            
    //         setPaymentDetails(null);

    //         const data = JSON.stringify({
    //             amount: parseFloat(amount),
    //             cryptoCurrency
    //         });

    //         const config = {
    //             method: 'post',
    //             maxBodyLength: Infinity,
    //             url: 'https://api.vertexglobal.io/auth/v1/fund',
    //             headers: { 
    //                 'Content-Type': 'application/json', 
    //                 'Authorization': `Bearer ${token}`, 
    //                 'Cookie': 'connect.sid=YOUR_SESSION_ID'
    //             },
    //             data: data,
    //         };

    //         const response = await axios.request(config);
    //         const paymentRecord = response.data.paymentRecord;

    //         if (response.data.status) {
    //             setPaymentDetails(paymentRecord);
    //             setModalIsOpen(true);
    //         } else {
    //             message.error('Failed to process payment.');
    //         }
    //     } catch (error) {
    //         console.error('Error processing payment:', error);
    //         message.error('Error processing payment.');
    //     } finally {
    //         setLoading(false); // Set loading to false after processing
    //     }
    // };

    const closeModal = () => {
        // Clear payment details when closing the modal
        setPaymentDetails(null);
        setModalIsOpen(false);
    };

    const fetchTransactions = async () => {
        try {
            const response = await axios.get('https://api.vertexglobal.io/auth/v1/fund/transactions', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const { data } = response.data;
            setWallet(response.data.wallet);
            setTransactions(data.map(tx => ({
                amount: tx.amount,
                type: tx.type,
                description: tx.description,
                date: new Date(tx.date).toLocaleDateString()
            })));
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    useEffect(() => {
        transactionsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [transactions]);


    const handleAddFunds = ()=>{
let data = JSON.stringify({
  amount: amount
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.vertexglobal.io/auth/v1/static-fund',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
  },
  data : data
};

axios.request(config)
.then((response) => {
    message.success(`Added ${amount}`);
})
.catch((error) => {
  console.log(error);
});

    }

    return (
        <div className='wallet-1'>
            <div className="wallet">
                <h1>Wallet <img src={Logo} alt="Wallet Icon" /> </h1>
                <div className="wallet-details">
                    <h2>${wallet}</h2>
                    <p>VTX/USDT</p>
                </div>
            </div>
            {!loading && (
            <div className="add-funds">
                <h1>Add Funds <img src={Logo} alt="Wallet Icon" /> </h1>
                <div className="form-floating mb-2 position-relative">
                    <div className="dl-in">
                        <p>$</p>
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="0"
                            min="4"
                            step="any"
                        />
                    </div>
                </div>
                <select
                    value={cryptoCurrency}
                    onChange={(e) => setCryptoCurrency(e.target.value)}
                    className="form-control"
                    id="floatingSelectCurrency"
                    style={{ width: '100%' }}
                    required
                >
                    <option value="">Select Cryptocurrency</option>
                    <option value="usdtbsc">BNB Smart Chain (BEP 20)</option>
                </select>
                <div >
                {!loading && (
                <button onClick={handleAddFunds} id="primary-btn">Add Funds</button>
            )}
                
            </div>
            </div>
             )}
               {loading && (
               <div className="add-funds">
 <ShimmerText line={4} gap={10} />
               </div>
                 )}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className='paymentPopup'
                contentLabel="Payment Details"
            >
                <h2>Payment Details</h2>
               
                {paymentDetails && (
                    <div>
                        <input
                            type="text"
                            readOnly
                            value={paymentDetails.pay_address}
                            style={{ marginBottom: '10px', width: '100%', padding: '8px' }}
                        />
                        
                       <div> <button onClick={copyToClipboard} id="primary-btn">Copy Address</button></div>
                        <QRCode value={paymentDetails.pay_address} />
                       <div >

                       <div className="table-responsive">

                       <Table striped bordered hover>
                      <thead>
                        <tr>
                        <th>Payment Id</th>
                            <th>Payment Status</th>
                            <th>Amount</th>
                            <th>Payment Address</th>
                            <th>Pay Currency</th>
                            <th>Order Id</th>
                            <th>Created At</th>
                    
                        </tr>
                      </thead>
                      <tbody>
                       
                          <tr >
                          
                              <td >{paymentDetails.payment_id}</td>
                              <td >{paymentDetails.payment_status}</td>
                              <td >{paymentDetails.price_amount}</td>
                              <td >{paymentDetails.pay_address}</td>
                              <td >{paymentDetails.price_currency}</td>
                              <td >{paymentDetails.order_id}</td>
                              <td >{moment(paymentDetails.created_at).format("DD/MM/YYYY")}</td>

                           
                          </tr>
                 
                      </tbody>
                    </Table>
                        </div>

                       <button onClick={closeModal} id="primary-btn">Close</button>
                       </div>
                    </div>
                )}
            </Modal>
            <div className="transactions">
                <h3>Transaction History</h3>
                <ol>
                    {transactions.map((transaction, index) => (
                        <li key={index}>
                            {transaction.amount} {transaction.type === 'credit' ? 'added to' : 'spent from'} {transaction.description} on {transaction.date}
                        </li>
                    ))}
                    <div ref={transactionsEndRef} />
                </ol>
            </div>
        </div>
    );
};

export default Wallet;
