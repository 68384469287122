// context/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ user: null, token: null, error: null, loading: false });

    useEffect(() => {
        const token = Cookies.get('authToken');
        if (token) {
            setAuth({ ...auth, token, user: null, loading: true });
            // Optionally, verify token here with an API call
        } else {
            setAuth({ ...auth, token: null, user: null });
        }
    }, []);

    const login = async (userName, password) => {
        setAuth({ ...auth, loading: true });
        try {
            const response = await fetch('https://api.vertexglobal.io/auth/v1/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userName, password }),
            });
            const data = await response.json();

            if (response.ok) {
                Cookies.set('authToken', data.userToken); 
                setAuth({ ...auth, user: data.user, token: data.userToken, loading: false });
                return { success: true };
            } else {
                setAuth({ ...auth, error: data.message, loading: false });
                return { success: false, message: data.message };
            }
        } catch (error) {
            setAuth({ ...auth, error: error.message, loading: false });
            return { success: false, message: error.message };
        }
    };

    const logout = () => {
        Cookies.remove('authToken');
        setAuth({ user: null, token: null, error: null, loading: false });
    };

    return (
        <AuthContext.Provider value={{ ...auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);