import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import DataTable from '../../../components/Table/Table';
import axios from 'axios'; // Import axios

const { Option } = Select;
const token = localStorage.getItem('userToken');

const IDActivation = () => {
  const [formData, setFormData] = useState({
    userId: '',
    name: '',
    fundWallet: '',
    package: '',
    investmentAmount: '',
  });
  const [teamData, setTeamData] = useState([]); 
 
  const [packages, setPackages] = useState([]); 
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/get-mining',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.request(config);
       
          setTeamData(response.data); 
       
      } catch (error) {
        console.error('Error fetching mining data:', error);
        message.error('Error fetching mining data');
      }
    };

    fetchTeamData();
  }, [token]);
  

  console.log(teamData)

  useEffect(() => {
    const fetchMiningPackages = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/get-mining-package',
          headers: { 
            'Authorization': `Bearer ${token}`,
          }
        };
        
        const response = await axios.request(config);
        setPackages(response.data || []); 
      } catch (error) {
        console.error('Error fetching mining packages:', error);
        message.error('Failed to fetch mining packages');
      }
    };
    
    const fetchUserDetails = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/user',
          headers: { 
            'Authorization': `Bearer ${token}`,
          }
        };
        
        const response = await axios.request(config);
        const userDetails = response.data.data;
        

        setFormData({
          userId: userDetails.userName || '',
          name: userDetails.fullName || '',
          fundWallet: userDetails.walletAmount || '0',
          package: '',
          investmentAmount: '',
        });
      } catch (error) {
        console.error('Error fetching user details:', error);
        message.error('Failed to fetch user details');
      }
    };
    
    // Call both API fetches
    fetchMiningPackages();
    fetchUserDetails();
  }, []);
  
  const handleUpdateClick = async () => {
    try {
      if (!formData.userId || !formData.investmentAmount) {
        message.error('Please fill out all required fields');
        return;
      }

      const data = JSON.stringify({
        amount: formData.investmentAmount,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.vertexglobal.io/auth/v1/add-mining',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log('Response:', JSON.stringify(response.data));

      // If successful, show a success message
      message.success('Mining amount added successfully!');

    } catch (error) {
      console.error('Error during mining activation:', error);
      message.error('Failed to add mining amount');
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (value) => {
    setFormData({
      ...formData,
      package: value,
    });
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Mining</h1>
        <div className="form-comp full">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Full Name"
              name="userId"
              value={formData.userId}
              onChange={handleInputChange}
              readOnly // Make it read-only if the user cannot modify the ID
            />
            <label htmlFor="floatingInput">User Id *</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              readOnly // Make it read-only if the user cannot modify the name
            />
            <label htmlFor="floatingInput">Name *</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingInput"
              placeholder="Fund Wallet"
              name="fundWallet"
              value={formData.fundWallet}
              onChange={handleInputChange}
              readOnly // If the user cannot modify the fund wallet amount, keep it read-only
            />
            <label htmlFor="floatingInput">Fund Wallet</label>
          </div>
          <div className="form-floating mb-3">
            <Select
              placeholder="Select Mining Package"
              className="form-control"
              id="floatingSelectCurrency"
              style={{ width: '100%' }}
              value={formData.package}
              onChange={handleSelectChange}
            >
              {packages.map((pkg) => (
                <Option key={pkg._id} value={pkg._id}>
                  {pkg.name} - ${pkg.amount}
                </Option>
              ))}
            </Select>
            <label htmlFor="floatingSelectCurrency">Select Package *</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingInput"
              placeholder="Investment Amount"
              name="investmentAmount"
              value={formData.investmentAmount}
              onChange={handleInputChange}
            />
            <label htmlFor="floatingInput">Investment Amount *</label>
          </div>
          <button id="primary-btn" onClick={handleUpdateClick}>Save</button>
        </div>
      </div>
      <DataTable heading="Mining History" data={teamData || []} />
    </main>
  );
};

export default IDActivation;
