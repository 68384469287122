import React from 'react';
import { Routes, Route } from 'react-router-dom';
import '../src/assets/css/core/libs.min.css';
import '../src/assets/css/coinex.min.css?v=4.1.0';
import '../src/assets/css/custom.min.css?v=4.1.0';
import '../src/assets/css/Global.css';
import Home from './pages/Home/home';
import Header from './common/Header/Header';
import Footer from './common/Footer/Footer';
import Login from './pages/Authentication/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Sidebar from './components/Sidebar/Sidebar';
import MiningIncome from './pages/FinancialReport/MiningIncome/Mining';
import MyProfile from './pages/Profile/MyProfile/MyProfile';
import ChangePassword from './pages/Profile/ChangePassword/ChangePassword';
import ChangeTransactionPassword from './pages/Profile/ChangeTransactionPassword/ChangeTransactionPassword';
import AddWalletAddress from './pages/Profile/AddWalletAddress/AddWalletAddress';
import UpdateWalletAddress from './pages/Profile/UpdateWalletAddress/UpdateWalletAddress';
import IDActivation from './pages/ActivationManager/IdActivation/IDActivation';
import PurchaseHistory from './pages/ActivationManager/PurchaseHistory/PurchaseHistory';
import FundRequest from './pages/FundManager/FundRequest/FundRequest';
import FundReceiveHistory from './pages/FundManager/FundReceiveHistory/FundReceiveHistroy';
import FundTransfer from './pages/FundManager/FundTransfer/FundTransfer';
import DirectReferral from './pages/TeamMember/DirectReferral/DirectReerral';
import MyTeam from './pages/TeamMember/MyTeam/MyTeam';
import LevelTeam from './pages/TeamMember/LevelTeam/LevelTeam';
import CoinStaking from './pages/CoinManager/CoinStaking/CoinStaking';
import CoinWithdraw from './pages/CoinManager/CoinWithdraw/CoinWithdraw';
import Withdraw from './pages/Wallet/Withdraw/Withdraw';
import Support from './pages/Support/Support';
import { AuthProvider } from './context/auth/AuthContext';
import Signup from './pages/Authentication/Signup/Signup';
import CoinCalculator from './components/CoinCalculator/CoinCaluculator';
import BoosterIncome from './pages/FinancialReport/BoosterIncome/BoosterIncome';
import FastTrackIncome from './pages/FinancialReport/FastTrackIncome/FastTrackIncome';
import WalletOutStanding from './pages/FinancialReport/WalletOutstanding/WalletOutStanding';
import WalletStatement from './pages/FinancialReport/WalletStatement/WalletStatement';
import RewardIncome from './pages/FinancialReport/RewardIncome/RewardIncome';
import OverRidingIncome from './pages/FinancialReport/OverRidingIncome/OverRidingIncome';
import LevelIncome from './pages/FinancialReport/LevelIncome/LevelIncome';
import BoosterIn from './pages/ActivationManager/BoosterIncome/BoosterIncome';
import FastTrack from './pages/ActivationManager/FastTrackIncome.jsx/fastTrackIncome';

function App() {
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<></>} /> */}


        {/* login */}
        <Route path="/" element={<AuthProvider><Login /></AuthProvider>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/register" element={<AuthProvider><Signup /></AuthProvider>} />
        <Route path="/dashboard" element={<><Sidebar /><Dashboard /></>} />
        {/* profile */}
      
        <Route path="/profile/change-password" element={<><Sidebar /><ChangePassword /></>} />
        <Route path="/profile/my-profile" element={<><Sidebar /><MyProfile /></>} />
        <Route path="/profile/transaction-password" element={<><Sidebar /><ChangeTransactionPassword /></>} />
       

         {/* Activation */}
         <Route path="/activation/id-activation" element={<><Sidebar /><IDActivation /></>} />
         <Route path="/activation/staking" element={<><Sidebar /><CoinStaking /></>} />
         <Route path="/activation/purchase-history" element={<><Sidebar /><PurchaseHistory /></>} />
         <Route path="/activation/booster" element={<><Sidebar /><BoosterIn /></>} />
         <Route path="/activation/fasttrack" element={<><Sidebar /><FastTrack /></>} />

          {/* Fund Manaer */}
          <Route path="/fund-manager/fund-request" element={<><Sidebar /><FundRequest /></>} />
          <Route path="/fund-manager/fund-transfer" element={<><Sidebar /><FundTransfer /></>} />
          <Route path="/fund-manager/fund-received-history" element={<><Sidebar /><FundReceiveHistory /></>} />
  
          {/* Team Member */}
          <Route path="/team-member/direct-referral" element={<><Sidebar /><DirectReferral /></>} />
          <Route path="/team-member/my-team" element={<><Sidebar /><MyTeam /></>} />
          <Route path="/team-member/level-team" element={<><Sidebar /><LevelTeam /></>} />

           {/* Coin Manager */}
        
          <Route path="/coin/withdraw" element={<><Sidebar /><CoinWithdraw /></>} />

           {/* Financial Report Manager */}
           <Route path="/report/mining-income" element={<><Sidebar /><MiningIncome /></>} />
          <Route path="/report/level-income" element={<><Sidebar /><LevelIncome /></>} />
          <Route path="/report/overriding-income" element={<><Sidebar /><OverRidingIncome /></>} />
          <Route path="/report/fast-track-income" element={<><Sidebar /><FastTrackIncome /></>} />
          <Route path="/report/booster-income" element={<><Sidebar /><BoosterIncome /></>} />
          <Route path="/report/reward-income" element={<><Sidebar /><RewardIncome /></>} />
          <Route path="/report/wallet-statement" element={<><Sidebar /><WalletStatement /></>} />
          <Route path="/report/wallet-outstanding" element={<><Sidebar /><WalletOutStanding /></>} />
   
          {/*Wallet*/}
          <Route path="/wallet/add-wallet-address" element={<><Sidebar /><AddWalletAddress /></>} />
          <Route path="/wallet/update-wallet-address" element={<><Sidebar /><UpdateWalletAddress /></>} />
          <Route path="/wallet/withdraw" element={<><Sidebar /><Withdraw /></>} />
          <Route path="/wallet/withdraw" element={<><Sidebar /><Withdraw /></>} />

           {/*Wallet*/}
           <Route path="/support" element={<><Sidebar /><Support /></>} />
           

      </Routes>
    </div>
  );
}

export default App;
