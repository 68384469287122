import React, { useState } from 'react';
import { message } from 'antd';
import axios from 'axios';

const ChangeTransactionPassword = () => {
  const [currentTransactionPassword, setCurrentTransactionPassword] = useState('');
  const [newTransactionPassword, setNewTransactionPassword] = useState('');
  const [confirmNewTransactionPassword, setConfirmNewTransactionPassword] = useState('');
  const token = localStorage.getItem('userToken');

  const handleUpdateClick = async () => {
    if (newTransactionPassword !== confirmNewTransactionPassword) {
      message.error('New transaction passwords do not match');
      return;
    }

    try {
      const data = JSON.stringify({
        currentTransactionPassword,
        newTransactionPassword
      });

      const response = await axios.patch('https://api.vertexglobal.io/auth/v1/user/', data, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      console.log(response.data);
      message.success('Transaction password updated successfully!');
    } catch (error) {
      console.log(error);
      message.error('Failed to update transaction password');
    }
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Change Transaction Password</h1>
        <div className="form-comp full">
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="currentTransactionPassword"
              placeholder="Current Transaction Password"
              value={currentTransactionPassword}
              onChange={(e) => setCurrentTransactionPassword(e.target.value)}
            />
            <label htmlFor="currentTransactionPassword">Current Transaction Password</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="newTransactionPassword"
              placeholder="New Transaction Password"
              value={newTransactionPassword}
              onChange={(e) => setNewTransactionPassword(e.target.value)}
            />
            <label htmlFor="newTransactionPassword">New Transaction Password</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="confirmNewTransactionPassword"
              placeholder="Confirm New Transaction Password"
              value={confirmNewTransactionPassword}
              onChange={(e) => setConfirmNewTransactionPassword(e.target.value)}
            />
            <label htmlFor="confirmNewTransactionPassword">Confirm New Transaction Password</label>
          </div>
          <button id="primary-btn" onClick={handleUpdateClick}>Save</button>
        </div>
      </div>
    </main>
  );
};

export default ChangeTransactionPassword;
