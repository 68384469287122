import React from 'react';
import { Select, message } from 'antd';
import DataTable from '../../../components/Table/Table';
const { Option } = Select;

const Data = {
    miningIncome: [
      {
        date: "2024-08-01",
        income: "1500 USD",
        minedCoins: 0.05,
        electricityCost: "200 USD",
        netIncome: "1300 USD"
      },
      {
        date: "2024-08-02",
        income: "1600 USD",
        minedCoins: 0.06,
        electricityCost: "220 USD",
        netIncome: "1380 USD"
      },
      {
        date: "2024-08-03",
        income: "1450 USD",
        minedCoins: 0.048,
        electricityCost: "210 USD",
        netIncome: "1240 USD"
      },
      {
          date: "2024-08-03",
          income: "1450 USD",
          minedCoins: 0.048,
          electricityCost: "210 USD",
          netIncome: "1240 USD"
        },
        {
            date: "2024-08-03",
            income: "1450 USD",
            minedCoins: 0.048,
            electricityCost: "210 USD",
            netIncome: "1240 USD"
          }
          ,
      {
          date: "2024-08-03",
          income: "1450 USD",
          minedCoins: 0.048,
          electricityCost: "210 USD",
          netIncome: "1240 USD"
        }
        ,
      {
          date: "2024-08-03",
          income: "1450 USD",
          minedCoins: 0.048,
          electricityCost: "210 USD",
          netIncome: "1240 USD"
        }
        ,
      {
          date: "2024-08-03",
          income: "1450 USD",
          minedCoins: 0.048,
          electricityCost: "210 USD",
          netIncome: "1240 USD"
        }
        ,
      {
          date: "2024-08-03",
          income: "1450 USD",
          minedCoins: 0.048,
          electricityCost: "210 USD",
          netIncome: "1240 USD"
        }
        ,
      {
          date: "2024-08-03",
          income: "1450 USD",
          minedCoins: 0.048,
          electricityCost: "210 USD",
          netIncome: "1240 USD"
        }
        ,
      {
          date: "2024-08-03",
          income: "1450 USD",
          minedCoins: 0.048,
          electricityCost: "210 USD",
          netIncome: "1240 USD"
        }
        ,
      {
          date: "2024-08-03",
          income: "1450 USD",
          minedCoins: 0.048,
          electricityCost: "210 USD",
          netIncome: "1240 USD"
        }
        
    ]
  };

const CoinWithdraw = () => {
  const handleUpdateClick = () => {
    message.success('Password updated successfully!');
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Coin Withdrawal        </h1>
        <div className="form-comp">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingInput" placeholder="Full Name" />
            <label htmlFor="floatingInput">User Id *</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingInput" placeholder="Mobile" />
            <label htmlFor="floatingInput">Name *</label>
          </div>
          
     

          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="floatingInput" placeholder="Email Address" />
            <label htmlFor="floatingInput">Income Wallet *</label>
          </div>

    
          <div className="form-floating mb-3">
            <Select
              placeholder="Select Currency"
              className="form-control"
              id="floatingSelectCurrency"
              style={{ width: '100%' }}
            >
              <Option value="bnb">12 Months</Option>
              <Option value="bnb">24 Months</Option>
            </Select>
            <label htmlFor="floatingSelectCurrency">Select Wallet Address</label>
          </div>

          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="floatingInput" placeholder="Email Address" />
            <label htmlFor="floatingInput">Withdrawl Amount *</label>
          </div>

          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="floatingInput" placeholder="Email Address" />
            <label htmlFor="floatingInput">Transaction Password *</label>
          </div>

          <button id="primary-btn" onClick={handleUpdateClick}>Withdraw</button>
        </div>

      
      </div>
      <DataTable heading="Withdraw History" data={Data.miningIncome} />
    </main>
  );
};

export default CoinWithdraw;
