import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select, message } from 'antd';
import DataTable from '../../../components/Table/Table';

const { Option } = Select;

const BoosterIn = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [stakingPackages, setStakingPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [stakeAmount, setStaking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState([]); 
  const token = localStorage.getItem('userToken');
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const userResponse = await axios.get('https://api.vertexglobal.io/auth/v1/user', {
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        });
        setUserDetails(userResponse.data.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    const fetchStakingPackages = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const packagesResponse = await axios.get('https://api.vertexglobal.io/auth/v1/staking/all', {
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        });
        setStakingPackages(packagesResponse.data);
      } catch (error) {
        console.error('Error fetching staking packages:', error);
      }
    };

    const fetchStake = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const packagesResponse = await axios.get('https://api.vertexglobal.io/auth/v1/mystake', {
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        });
        setStaking(packagesResponse.data.stakings);
      } catch (error) {
        console.error('Error fetching staking data:', error);
      }
    };

    fetchStake();
    fetchUserData();
    fetchStakingPackages();
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/myboosterincome',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.request(config);
       
          setTeamData(response.data.data); 
       
      } catch (error) {
        console.error('Error fetching mining data:', error);
        message.error('Error fetching mining data');
      }
    };

    fetchTeamData();
  }, [token]);
  
  const handleUpdateClick = async () => {
    if (!investmentAmount) {
      message.error('Please select a Investment amount.');
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      const data = JSON.stringify({
        depositAmount: investmentAmount
      });
      await axios.post('https://api.vertexglobal.io/auth/v1/add-boosterincome', data, {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}` 
        }
      });
      message.success('Booster Income request submitted successfully!');
    
    } catch (error) {
      console.error('Error submitting staking request:', error);
      message.error('Failed to submit staking request.');
    }
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Booster Income</h1>
        <div className="form-comp">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingInput" placeholder="Full Name" value={userDetails ? userDetails.userName : ''} readOnly />
            <label htmlFor="floatingInput">User Id *</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingInput" placeholder="Mobile" value={userDetails ? userDetails.fullName : ''} readOnly />
            <label htmlFor="floatingInput">Name *</label>
          </div>

          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="floatingInput" placeholder="Purchase Wallet" value={userDetails ? userDetails.walletAmount : ''} readOnly />
            <label htmlFor="floatingInput">Purchase Wallet *</label>
          </div>

          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="floatingInput" value={userDetails ? userDetails.tokenRate : ''} readOnly placeholder="Token Rate" />
            <label htmlFor="floatingInput">Token Rate *</label>
          </div>

          

          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingInput"
              placeholder="Investment Amount"
              value={investmentAmount}
              onChange={e => setInvestmentAmount(e.target.value)}
            />
            <label htmlFor="floatingInput">Investment Amount *</label>
          </div>

          <button id="primary-btn" onClick={handleUpdateClick}>Add Now</button>
        </div>
       
      </div>
      <DataTable heading="Booster Income History" data={teamData || []} />
    </main>
  );
};

export default BoosterIn;
