import React from 'react';
import { Select, message } from 'antd';
import DataTable from '../../../components/Table/Table';
const { Option } = Select;

const Data = {
  miningIncome: [
    {
      srNo: '1',
      senderId: "VX0001",
      senderName: "Aman Sharma",
      receiverId: "VX0002",
      receiverName: "Abhishek Yadav",
      receiverAmount: "102 usdtbsc",
      date: "2024-08-01",
    },
    {
      srNo: '2',
      senderId: "VX0001",
      senderName: "Aman Sharma",
      receiverId: "VX0002",
      receiverName: "Abhishek Yadav",
      receiverAmount: "102 usdtbsc",
      date: "2024-08-01",
    },
    
    {
      srNo: '3',
      senderId: "VX0001",
      senderName: "Aman Sharma",
      receiverId: "VX0002",
      receiverName: "Abhishek Yadav",
      receiverAmount: "102 usdtbsc",
      date: "2024-08-01",
    },
      
  ]
};

const FundReceiveHistory = () => {
  const handleUpdateClick = () => {
    message.success('Password updated successfully!');
  };

  return (
    <main className="main-content">
   
      <DataTable heading="Fund Receive History" data={Data.miningIncome} />
    </main>
  );
};

export default FundReceiveHistory;
